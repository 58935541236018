<template>
  <div>
    <b-container>
      <b-row class="text-center">
        <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">{{ $t('billing_details_title') }}</h1>
      </b-row>
    </b-container>
    <BillingForm v-if="showContent"
                 :company-name="billingDetails.company_name"
                 :full-name="billingDetails.full_name"
                 :country="billingDetails.country"
                 :address="billingDetails.address"
                 :city="billingDetails.city"
                 :zip-code="billingDetails.zip_code"
                 :vat-id="billingDetails.vat_id"
                 :billing-details="hasBilling"
    />
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner" />
      <div>{{ $t('loading') }}</div>
    </div>
  </div>
</template>

<script>
import BillingForm from '../../components/Forms/BillingForm.vue'
import ApiService from '../../apiService.js'

export default {
  name: 'BillingDetails',
  components: {
    BillingForm
  },
  data () {
    return {
      localeValue: localStorage.getItem('locale'),
      showContent: false,
      billingDetails: {
        company_name: '',
        full_name: '',
        country: '',
        address: '',
        city: '',
        zip_code: '',
        vat_id: ''
      },
      hasBilling: false
    }
  },
  mounted () {
    this.getBillingDetails()
  },
  methods: {
    getBillingDetails () {
      this.showContent = false
      ApiService.getBillingDetails().then(response => {
        if (response.data.data === null) {
          this.showContent = true
        } else {
          this.billingDetails = response.data.data
          this.hasBilling = true
          this.showContent = true
        }
      }).catch((e) => {
      })
    }
  }
}
</script>

<style scoped>

</style>
