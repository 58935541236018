<template>
  <div class="container">
    <div class="content-box p-3">
      <div id="form-error-details" class="p-2 alert alert-danger text-left">
        <p><i class="fa-solid fa-triangle-exclamation"></i> {{ $t('billing_form_error') }}</p>
        <ul></ul>
      </div>
      <div id="form-success-details" class="p-2 alert alert-success text-left">
        <p class="m-0">
          <i class="fa-solid fa-check" aria-hidden="true"></i>
          {{ $t('billing_submit_successful') }}
        </p>
      </div>

      <form v-on:submit.prevent="handleSubmit" class="m-4">
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsCompanyName">{{ $t('company_name') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <input id="billingDetailsCompanyName" v-bind:class="{ 'border-danger': validation.companyName}" type="text" class="form-control" v-model="billingDetailsCompanyName">
            <small>{{ $t('optional') }}</small>
          </div>
        </div>
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsFullName">{{ $t('full_name') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <input id="billingDetailsFullName" v-bind:class="{ 'border-danger': validation.fullName}" type="text" class="form-control" v-model="billingDetailsFullName" required>
          </div>
        </div>
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsCountry">{{ $t('country_text') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <b-form-select
              id="billingDetailsCountry"
              :options="$t('country')"
              v-model="billingDetailsCountry"
              v-bind:class="{ 'border-danger': validation.country}"
              required
            >
              <template slot="first">
                <option :value="null">{{ $t('first_time_login_country_select_default') }}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsAddress">{{ $t('address') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <input id="billingDetailsAddress" v-bind:class="{ 'border-danger': validation.address}" type="text" class="form-control" v-model="billingDetailsAddress" required>
          </div>
        </div>
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsCity">{{ $t('city') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <input id="billingDetailsCity" v-bind:class="{ 'border-danger': validation.city}" type="text" class="form-control" v-model="billingDetailsCity" required>
          </div>
        </div>
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsZipCode">{{ $t('zip_code') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <input id="billingDetailsZipCode" v-bind:class="{ 'border-danger': validation.zipCode}" type="text" class="form-control" v-model="billingDetailsZipCode">
            <small>{{ $t('optional') }}</small>
          </div>
        </div>
        <div class="form-group row mb-0 pb-3 text-left">
          <label class="col-12 col-sm-3 col-lg-2 col-form-label" for="billingDetailsVatId">{{ $t('vat_id') }}</label>
          <div class="col-12 col-sm-9 col-lg-10">
            <input id="billingDetailsVatId" v-bind:class="{ 'border-danger': validation.vatId}" type="text" class="form-control" v-model="billingDetailsVatId">
            <small>{{ $t('vat_id_help') }}</small>
          </div>
        </div>

        <div class="offset-0 offset-sm-3 offset-lg-2 text-right w-25">
          <b-button v-if="!saving" variant="primary" @click="handleSubmit" block>{{ $t('billing_submit_button') }}</b-button>
          <b-button v-else variant="primary" disabled="disabled" block>
            <b-spinner small/>
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'

export default {
  name: 'BillingForm',
  props: {
    companyName: String,
    fullName: String,
    country: String,
    address: String,
    city: String,
    zipCode: String,
    vatId: String,
    billingDetails: Boolean
  },
  data () {
    return {
      billingDetailsCompanyName: this.companyName,
      billingDetailsFullName: this.fullName,
      billingDetailsCountry: this.country,
      billingDetailsAddress: this.address,
      billingDetailsCity: this.city,
      billingDetailsZipCode: this.zipCode,
      billingDetailsVatId: this.vatId,
      validation: {
        companyName: false,
        fullName: false,
        country: false,
        address: false,
        city: false,
        zipCode: false,
        vatId: false
      },
      saving: false,
      newDetails: this.billingDetails,
      upload_data: null
    }
  },
  methods: {
    handleSubmit () {
      this.saving = true
      if (this.validateForm()) {
        if (!this.newDetails) {
          ApiService.postBillingDetails(this.upload_data).then(() => {
            document.getElementById('form-success-details').style.display = 'block'
            this.saving = false
            this.newDetails = true
          }).catch((e) => {
            this.saving = false
            this.errorHandling(e.response.data)
            return false
          })
        } else {
          ApiService.putBillingDetails(this.upload_data).then(() => {
            document.getElementById('form-success-details').style.display = 'block'
            this.saving = false
          }).catch((e) => {
            this.saving = false
            this.errorHandling(e.response.data)
            return false
          })
        }
      } else {
        this.saving = false
      }
    },
    validateForm () {
      const data = {
        'company_name': this.billingDetailsCompanyName,
        'full_name': this.billingDetailsFullName,
        'country': this.billingDetailsCountry,
        'address': this.billingDetailsAddress,
        'city': this.billingDetailsCity,
        'zip_code': this.billingDetailsZipCode,
        'vat_id': this.billingDetailsVatId
      }

      // Clear existing errors/success
      document.getElementById('form-success-details').style.display = 'none'
      let ul = document.getElementById('form-error-details').getElementsByTagName('ul')[0]
      ul.innerText = ''
      ul.parentNode.style.display = 'none'

      if (!data.full_name) {
        this.validation.fullName = true

        let li = document.createElement('li')
        li.appendChild(document.createTextNode(this.$t('full_name')))
        ul.appendChild(li)
      } else {
        this.validation.fullName = false
      }

      if (!data.country) {
        this.validation.country = true

        let li = document.createElement('li')
        li.appendChild(document.createTextNode(this.$t('country_text')))
        ul.appendChild(li)
      } else {
        this.validation.country = false
      }

      if (!data.address) {
        this.validation.address = true

        let li = document.createElement('li')
        li.appendChild(document.createTextNode(this.$t('address')))
        ul.appendChild(li)
      } else {
        this.validation.address = false
      }

      if (!data.city) {
        this.validation.city = true

        let li = document.createElement('li')
        li.appendChild(document.createTextNode(this.$t('city')))
        ul.appendChild(li)
      } else {
        this.validation.city = false
      }

      // If we have errors, show them and scroll to the top
      if (ul.getElementsByTagName('li').length > 0) {
        ul.parentNode.style.display = 'block'
        window.scrollTo(0, 0)

        return false
      }

      this.upload_data = data
      return true
    },
    errorHandling (resp) {
      let ul = document.getElementById('form-error-details').getElementsByTagName('ul')[0]
      for (let key in resp.errors.children) {
        if (resp.errors.children[key].errors !== undefined) {
          let li = document.createElement('li')
          ul.appendChild(li)
          li.appendChild(document.createTextNode(resp.errors.children[key].errors))
          this.validation[key] = true
        }
      }

      ul.parentNode.style.display = 'block'
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
  #form-success-details,
  #form-error-details {
    display: none;
  }

  #form-error-details ul li {
    list-style-type: disc;
    margin-left: 0;
  }
</style>
